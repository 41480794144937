import { createStore } from 'zustand/vanilla'
import { persist, devtools } from 'zustand/middleware'

export interface AuthStore {
    accessToken: string
    refreshToken: string
    setRegistrationDetails: (details) => void
    registrationDetails: {}
    setAccessToken: (accessToken: string) => void
    setRefreshToken: (refreshToken: string) => void
    resetAuthState: () => void
    setCurrentComplex: (currentPoolComplex: {
        id: string
        name: string
        organization: string
        organization_branch_address: string
    }) => void
    currentPoolComplex: {
        id: string
        name: string
        organization: string
        organization_branch_address: string
    }
}

const initialAuthState: AuthStore = {
    setRegistrationDetails: () => {},
    registrationDetails: {},
    accessToken: '',
    refreshToken: '',
    setAccessToken: () => {},
    setRefreshToken: () => {},
    resetAuthState: () => {},
    setCurrentComplex: () => {},
    currentPoolComplex: {
        id: '',
        name: '',
        organization: '',
        organization_branch_address: '',
    },
}

export const globalState = createStore<
    AuthStore,
    [['zustand/devtools', never], ['zustand/persist', AuthStore]]
>(
    devtools(
        persist(
            (set) => ({
                ...initialAuthState,
                setAccessToken: (accessToken: string) => set({ accessToken }),
                setRefreshToken: (refreshToken: string) =>
                    set({ refreshToken }),
                resetAuthState: () => set(initialAuthState),
                setCurrentComplex: (currentPoolComplex: {
                    id: string
                    name: string
                    organization: string
                    organization_branch_address: string
                }) => set({ currentPoolComplex }),
                setRegistrationDetails: (registrationDetails) =>
                    set({ registrationDetails }),
            }),
            { name: 'globalState' }
        )
    )
)
export function getcurrentbranch() {
    return (
        globalState.getState().currentPoolComplex || {
            id: '',
            name: '',
            organization: '',
        }
    )
}
export default globalState
