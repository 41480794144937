/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiBranchManagersListParams {
    /** @pattern ^[0-9]+$ */
    branchId: string
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiBranchOperatorsListParams {
    /** @pattern ^[0-9]+$ */
    branchId: string
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiOrganizationBranchListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiOrganizationListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiPoolListParams {
    health_ministry_code?: string
    name?: string
    organization_branch?: number
    organization_branch__name?: string
    /** A page number within the paginated result set. */
    page?: number
    pool_type?: string
}

export interface ApiPoolLogReportAttachmentListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiPoolLogReportListParams {
    commented?: boolean
    /** A page number within the paginated result set. */
    page?: number
    pool__id?: number
    pool__name?: string
    pool__organization_branch__name?: string
    /** @format date */
    pool_log_date?: string
    /** @format date */
    pool_log_range_after?: string
    /** @format date */
    pool_log_range_before?: string
    signer?: number
}

export interface ApiSchemaRetrieveParams {
    format?: FormatEnum
    lang?: LangEnum
}

export enum ApiSchemaRetrieveParams1FormatEnum {
    Json = 'json',
    Yaml = 'yaml',
}

export enum ApiSchemaRetrieveParams1LangEnum {
    Af = 'af',
    Ar = 'ar',
    ArDz = 'ar-dz',
    Ast = 'ast',
    Az = 'az',
    Be = 'be',
    Bg = 'bg',
    Bn = 'bn',
    Br = 'br',
    Bs = 'bs',
    Ca = 'ca',
    Ckb = 'ckb',
    Cs = 'cs',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dsb = 'dsb',
    El = 'el',
    En = 'en',
    EnAu = 'en-au',
    EnGb = 'en-gb',
    Eo = 'eo',
    Es = 'es',
    EsAr = 'es-ar',
    EsCo = 'es-co',
    EsMx = 'es-mx',
    EsNi = 'es-ni',
    EsVe = 'es-ve',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Fi = 'fi',
    Fr = 'fr',
    Fy = 'fy',
    Ga = 'ga',
    Gd = 'gd',
    Gl = 'gl',
    He = 'he',
    Hi = 'hi',
    Hr = 'hr',
    Hsb = 'hsb',
    Hu = 'hu',
    Hy = 'hy',
    Ia = 'ia',
    Id = 'id',
    Ig = 'ig',
    Io = 'io',
    Is = 'is',
    It = 'it',
    Ja = 'ja',
    Ka = 'ka',
    Kab = 'kab',
    Kk = 'kk',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Ky = 'ky',
    Lb = 'lb',
    Lt = 'lt',
    Lv = 'lv',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mr = 'mr',
    Ms = 'ms',
    My = 'my',
    Nb = 'nb',
    Ne = 'ne',
    Nl = 'nl',
    Nn = 'nn',
    Os = 'os',
    Pa = 'pa',
    Pl = 'pl',
    Pt = 'pt',
    PtBr = 'pt-br',
    Ro = 'ro',
    Ru = 'ru',
    Sk = 'sk',
    Sl = 'sl',
    Sq = 'sq',
    Sr = 'sr',
    SrLatn = 'sr-latn',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Tk = 'tk',
    Tr = 'tr',
    Tt = 'tt',
    Udm = 'udm',
    Ug = 'ug',
    Uk = 'uk',
    Ur = 'ur',
    Uz = 'uz',
    Vi = 'vi',
    ZhHans = 'zh-hans',
    ZhHant = 'zh-hant',
}

export interface ApiSubscriptionListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiTariffListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiToxicReportAttachmentListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiToxicReportListParams {
    month?: number
    organization_branch?: number
    /** A page number within the paginated result set. */
    page?: number
    /** @format date */
    report_date?: string
    substance_receiver_name?: string
    year?: number
}

export interface ApiToxicSubstanceLineListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiToxicSubstanceListParams {
    /** A page number within the paginated result set. */
    page?: number
}

export interface ApiUsersListParams {
    /** A page number within the paginated result set. */
    page?: number
}

/**
 * * `Hotel` - Hotel
 * * `Country` - Country
 * * `Kibbutz` - Kibbutz
 * * `Yishuv` - Yishuv
 * * `Therapeutic Pool` - Therapeutic Pool
 * * `Water Park` - Water Park
 * * `Nursing Home` - Nursing Home
 * * `Other` - Other
 */
export enum ComplexTypeEnum {
    Hotel = 'Hotel',
    Country = 'Country',
    Kibbutz = 'Kibbutz',
    Yishuv = 'Yishuv',
    TherapeuticPool = 'Therapeutic Pool',
    WaterPark = 'Water Park',
    NursingHome = 'Nursing Home',
    Other = 'Other',
}

export enum FormatEnum {
    Json = 'json',
    Yaml = 'yaml',
}

export enum LangEnum {
    Af = 'af',
    Ar = 'ar',
    ArDz = 'ar-dz',
    Ast = 'ast',
    Az = 'az',
    Be = 'be',
    Bg = 'bg',
    Bn = 'bn',
    Br = 'br',
    Bs = 'bs',
    Ca = 'ca',
    Ckb = 'ckb',
    Cs = 'cs',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dsb = 'dsb',
    El = 'el',
    En = 'en',
    EnAu = 'en-au',
    EnGb = 'en-gb',
    Eo = 'eo',
    Es = 'es',
    EsAr = 'es-ar',
    EsCo = 'es-co',
    EsMx = 'es-mx',
    EsNi = 'es-ni',
    EsVe = 'es-ve',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Fi = 'fi',
    Fr = 'fr',
    Fy = 'fy',
    Ga = 'ga',
    Gd = 'gd',
    Gl = 'gl',
    He = 'he',
    Hi = 'hi',
    Hr = 'hr',
    Hsb = 'hsb',
    Hu = 'hu',
    Hy = 'hy',
    Ia = 'ia',
    Id = 'id',
    Ig = 'ig',
    Io = 'io',
    Is = 'is',
    It = 'it',
    Ja = 'ja',
    Ka = 'ka',
    Kab = 'kab',
    Kk = 'kk',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Ky = 'ky',
    Lb = 'lb',
    Lt = 'lt',
    Lv = 'lv',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mr = 'mr',
    Ms = 'ms',
    My = 'my',
    Nb = 'nb',
    Ne = 'ne',
    Nl = 'nl',
    Nn = 'nn',
    Os = 'os',
    Pa = 'pa',
    Pl = 'pl',
    Pt = 'pt',
    PtBr = 'pt-br',
    Ro = 'ro',
    Ru = 'ru',
    Sk = 'sk',
    Sl = 'sl',
    Sq = 'sq',
    Sr = 'sr',
    SrLatn = 'sr-latn',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Tk = 'tk',
    Tr = 'tr',
    Tt = 'tt',
    Udm = 'udm',
    Ug = 'ug',
    Uk = 'uk',
    Ur = 'ur',
    Uz = 'uz',
    Vi = 'vi',
    ZhHans = 'zh-hans',
    ZhHant = 'zh-hant',
}

export interface LoginRequest {
    /** @minLength 1 */
    phone: string
}

export interface ManagerList {
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name?: string
    full_name: string
    id: number
    /**
     * צילום רשיון מפעיל בריכה
     * @format uri
     */
    operator_license_img?: string | null
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    role: RoleEnum
    /**
     * תמונת משתמש
     * @format uri
     */
    user_photo?: string | null
}

export interface ManagerListRequest {
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name?: string
    /**
     * צילום רשיון מפעיל בריכה
     * @format binary
     */
    operator_license_img?: File | null
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

export type NullEnum = null

export interface Organization {
    id: number
    /**
     * שם
     * @maxLength 200
     */
    name: string
}

export interface OrganizationBranch {
    /** סוג מתחם */
    complex_type?: ComplexTypeEnum | NullEnum | null
    id: number
    managers: PublicUser[]
    /**
     * שם
     * @maxLength 200
     */
    name: string
    organization?: Organization
    /**
     * כתובת הסניף
     * @maxLength 200
     */
    organization_branch_address: string
}

export interface OrganizationBranchRequest {
    /** סוג מתחם */
    complex_type?: ComplexTypeEnum | NullEnum | null
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name: string
    organization?: OrganizationRequest
    /**
     * כתובת הסניף
     * @minLength 1
     * @maxLength 200
     */
    organization_branch_address: string
    organization_id?: number
}

export interface OrganizationRequest {
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name: string
}

export interface PaginatedManagerListList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: ManagerList[]
}

export interface PaginatedOrganizationBranchList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: OrganizationBranch[]
}

export interface PaginatedOrganizationList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: Organization[]
}

export interface PaginatedPoolList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: Pool[]
}

export interface PaginatedPoolLogReportAttachmentList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: PoolLogReportAttachment[]
}

export interface PaginatedPoolLogReportList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: PoolLogReport[]
}

export interface PaginatedSubscriptionList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: Subscription[]
}

export interface PaginatedTariffList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: Tariff[]
}

export interface PaginatedToxicReportAttachmentList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: ToxicReportAttachment[]
}

export interface PaginatedToxicReportList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: ToxicReport[]
}

export interface PaginatedToxicSubstanceLineList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: ToxicSubstanceLine[]
}

export interface PaginatedToxicSubstanceList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: ToxicSubstance[]
}

export interface PaginatedUserDetailsList {
    /** @example 123 */
    count: number
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null
    results: UserDetails[]
}

export interface PatchedManagerListRequest {
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name?: string
    /**
     * צילום רשיון מפעיל בריכה
     * @format binary
     */
    operator_license_img?: File | null
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

export interface PatchedOrganizationBranchRequest {
    /** סוג מתחם */
    complex_type?: ComplexTypeEnum | NullEnum | null
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name?: string
    organization?: OrganizationRequest
    /**
     * כתובת הסניף
     * @minLength 1
     * @maxLength 200
     */
    organization_branch_address?: string
    organization_id?: number
}

export interface PatchedOrganizationRequest {
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name?: string
}

export interface PatchedPoolLogReportAttachmentRequest {
    /**
     * שם הנספח
     * @minLength 1
     * @maxLength 200
     */
    attachment_name?: string
    /**
     * קישור לנספח
     * @format binary
     */
    attachment_url?: File
    /** דוח יומן בריכה */
    pool_log_report?: number
}

export interface PatchedPoolLogReportRequest {
    /** הערות */
    comments?: string | null
    /**
     * כלור חופשי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    free_chlorine?: string
    /**
     * כלור כללי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    general_chlorine?: string | null
    /**
     * יש שימוש בחומצה ציאנורית
     * @default false
     */
    is_using_cyanuric_acid?: boolean
    /**
     * יש שימוש בהלוברום
     * @default false
     */
    is_using_halobrom?: boolean
    /**
     * עכירות
     * NTU
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    opacity?: string
    /**
     * ערך הגבה
     * pH
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    ph?: string
    /** בריכה */
    pool?: number
    /**
     * תאריך הבדיקה של הבריכה
     * @format date-time
     */
    pool_log_date?: string
    /**
     * רדוקס
     * @min 0
     * @max 3000
     */
    redox?: number | null
    /**
     * חתימה
     * @format binary
     */
    signature?: File | null
    signer?: number | null
    /**
     * טמפרטורה
     * C°
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    temperature?: string
}

export interface PatchedPoolRequest {
    /** הערות */
    comments?: string | null
    /**
     * קוד משרד הבריאות
     * @maxLength 30
     */
    health_ministry_code?: string | null
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name?: string
    /** מתחם בריכות */
    organization_branch?: number
    /**
     * כתובת הבריכה
     * @maxLength 30
     */
    pool_address?: string | null
    /**
     * תמונה של הבריכה
     * @format binary
     */
    pool_image?: File | null
    /** סוג בריכה */
    pool_type?: PoolTypeEnum
}

export interface PatchedSubscriptionRequest {
    /**
     * ביטל מנוי
     * @default false
     */
    has_cancelled?: boolean
    /**
     * עלות חודשית
     * @format decimal
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    monthly_cost?: string
    /** מתחם בריכות */
    organization_branch?: number
    /** שיטת תשלום */
    payment_method?: PaymentMethodEnum
    /**
     * תעריף עבור
     * @minLength 1
     * @maxLength 200
     */
    rate_for?: string
    /**
     * תאריך התחלה
     * @format date
     */
    start_date?: string
}

export interface PatchedTariffRequest {
    /**
     * תעריף פעיל
     * @default false
     */
    active_tariff?: boolean
    /**
     * עלות חודשית
     * @format decimal
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    monthly_cost?: string
    /**
     * תעריף עבור
     * @minLength 1
     * @maxLength 200
     */
    rate_for?: string
}

export interface PatchedToxicReportAttachmentRequest {
    /**
     * שם הנספח
     * @minLength 1
     * @maxLength 200
     */
    attachment_name?: string
    /**
     * קישור לנספח
     * @format binary
     */
    attachment_url?: File
    /** דוח פנקס רעלים */
    toxic_report?: number
}

export interface PatchedToxicReportRequest {
    /** הערות */
    comments?: string | null
    /** מתחם בריכות */
    organization_branch?: number
    /** חתימה */
    signature?: string | null
    /**
     * שם מקבל החומר
     * @minLength 1
     * @maxLength 50
     */
    substance_receiver_name?: string
    /**
     * תאריך דוח הרעלים
     * @format date-time
     */
    toxic_report_date?: string
    toxic_substance_line_create?: ToxicSubstanceLineCreateRequest[]
}

export interface PatchedToxicSubstanceLineRequest {
    /**
     * כמות החומר
     * @min 0
     * @max 20000
     */
    substance_amount?: number
    /** סוג כמות החומר */
    substance_amount_type?: SubstanceAmountTypeEnum
    /**
     * מספר סידורי של החומר
     * @minLength 1
     * @maxLength 200
     */
    substance_serial_number?: string
    /**
     * שם הספק
     * @minLength 1
     * @maxLength 50
     */
    supplier_name?: string
    /** דוח פנקס רעלים */
    toxic_report?: number
}

export interface PatchedUserDetailsRequest {
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name?: string
    /**
     * צילום רשיון מפעיל בריכה
     * @format binary
     */
    operator_license_img?: File | null
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

/**
 * * `הוראת קבע` - הוראת קבע
 * * `אשראי` - אשראי
 */
export enum PaymentMethodEnum {
    Valueהוראתקבע = 'הוראת קבע',
    Valueאשראי = 'אשראי',
}

export interface Pool {
    /** הערות */
    comments?: string | null
    /**
     * מועד יצירה
     * @format date-time
     */
    created_at: string
    /**
     * קוד משרד הבריאות
     * @maxLength 30
     */
    health_ministry_code?: string | null
    id: number
    /**
     * שם
     * @maxLength 200
     */
    name: string
    /** מתחם בריכות */
    organization_branch: number
    organization_branch_name: string
    /**
     * כתובת הבריכה
     * @maxLength 30
     */
    pool_address?: string | null
    /**
     * תמונה של הבריכה
     * @format uri
     */
    pool_image?: string | null
    /** סוג בריכה */
    pool_type: PoolTypeEnum
    /**
     * מועד עידכון
     * @format date-time
     */
    updated_at: string
}

export interface PoolLogReport {
    /** הערות */
    comments?: string | null
    /**
     * מועד יצירה
     * @format date-time
     */
    created_at: string
    /**
     * כלור חופשי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    free_chlorine: string
    /**
     * כלור כללי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    general_chlorine?: string | null
    id: number
    /**
     * יש שימוש בחומצה ציאנורית
     * @default false
     */
    is_using_cyanuric_acid?: boolean
    /**
     * יש שימוש בהלוברום
     * @default false
     */
    is_using_halobrom?: boolean
    /**
     * עכירות
     * NTU
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    opacity: string
    organization_branch: string
    organization_branch_name: string
    /**
     * ערך הגבה
     * pH
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    ph: string
    /** בריכה */
    pool: number
    /**
     * תאריך הבדיקה של הבריכה
     * @format date-time
     */
    pool_log_date: string
    pool_name: string
    /**
     * רדוקס
     * @min 0
     * @max 3000
     */
    redox?: number | null
    /**
     * חתימה
     * @format uri
     */
    signature?: string | null
    signer_data: PublicUser
    /**
     * טמפרטורה
     * C°
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    temperature: string
    /**
     * מועד עידכון
     * @format date-time
     */
    updated_at: string
}

export interface PoolLogReportAttachment {
    /**
     * שם הנספח
     * @maxLength 200
     */
    attachment_name: string
    /**
     * קישור לנספח
     * @format uri
     */
    attachment_url: string
    id: number
    /** דוח יומן בריכה */
    pool_log_report: number
}

export interface PoolLogReportAttachmentRequest {
    /**
     * שם הנספח
     * @minLength 1
     * @maxLength 200
     */
    attachment_name: string
    /**
     * קישור לנספח
     * @format binary
     */
    attachment_url: File
    /** דוח יומן בריכה */
    pool_log_report: number
}

export interface PoolLogReportRequest {
    /** הערות */
    comments?: string | null
    /**
     * כלור חופשי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    free_chlorine: string
    /**
     * כלור כללי
     * ppm
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    general_chlorine?: string | null
    /**
     * יש שימוש בחומצה ציאנורית
     * @default false
     */
    is_using_cyanuric_acid?: boolean
    /**
     * יש שימוש בהלוברום
     * @default false
     */
    is_using_halobrom?: boolean
    /**
     * עכירות
     * NTU
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    opacity: string
    /**
     * ערך הגבה
     * pH
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    ph: string
    /** בריכה */
    pool: number
    /**
     * תאריך הבדיקה של הבריכה
     * @format date-time
     */
    pool_log_date: string
    /**
     * רדוקס
     * @min 0
     * @max 3000
     */
    redox?: number | null
    /**
     * חתימה
     * @format binary
     */
    signature?: File | null
    signer?: number | null
    /**
     * טמפרטורה
     * C°
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
     */
    temperature: string
}

export interface PoolRequest {
    /** הערות */
    comments?: string | null
    /**
     * קוד משרד הבריאות
     * @maxLength 30
     */
    health_ministry_code?: string | null
    /**
     * שם
     * @minLength 1
     * @maxLength 200
     */
    name: string
    /** מתחם בריכות */
    organization_branch: number
    /**
     * כתובת הבריכה
     * @maxLength 30
     */
    pool_address?: string | null
    /**
     * תמונה של הבריכה
     * @format binary
     */
    pool_image?: File | null
    /** סוג בריכה */
    pool_type: PoolTypeEnum
}

/**
 * * `ג'קוזי` - HOT_TUB
 * * `מחוממת` - WARM
 * * `קייצית` - SUMMER
 * * `פעוטות` - TODDLERS
 * * `טיפולית` - THERAPEUTIC
 * * `גלים` - WAVES
 */
export enum PoolTypeEnum {
    Valueגקוזי = "ג'קוזי",
    Valueמחוממת = 'מחוממת',
    Valueקייצית = 'קייצית',
    Valueפעוטות = 'פעוטות',
    Valueטיפולית = 'טיפולית',
    Valueגלים = 'גלים',
}

export interface PublicUser {
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name?: string
    id: number
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * צילום רשיון מפעיל בריכה
     * @format uri
     */
    operator_license_img?: string | null
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    role: RoleEnum
    /**
     * תמונת משתמש
     * @format uri
     */
    user_photo?: string | null
}

export interface PublicUserRequest {
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name?: string
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * צילום רשיון מפעיל בריכה
     * @format binary
     */
    operator_license_img?: File | null
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

export enum RoleEnum {
    OrganizationManager = 'organization_manager',
    PoolsManager = 'pools_manager',
    Operator = 'operator',
}

export interface Subscription {
    /**
     * מועד יצירה
     * @format date-time
     */
    created_at: string
    /**
     * ביטל מנוי
     * @default false
     */
    has_cancelled?: boolean
    id: number
    /**
     * עלות חודשית
     * @format decimal
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    monthly_cost: string
    /** מתחם בריכות */
    organization_branch: number
    /** שיטת תשלום */
    payment_method: PaymentMethodEnum
    /**
     * תעריף עבור
     * @maxLength 200
     */
    rate_for: string
    /**
     * תאריך התחלה
     * @format date
     */
    start_date: string
    /**
     * מועד עידכון
     * @format date-time
     */
    updated_at: string
}

export interface SubscriptionRequest {
    /**
     * ביטל מנוי
     * @default false
     */
    has_cancelled?: boolean
    /**
     * עלות חודשית
     * @format decimal
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    monthly_cost: string
    /** מתחם בריכות */
    organization_branch: number
    /** שיטת תשלום */
    payment_method: PaymentMethodEnum
    /**
     * תעריף עבור
     * @minLength 1
     * @maxLength 200
     */
    rate_for: string
    /**
     * תאריך התחלה
     * @format date
     */
    start_date: string
}

/**
 * * `ליטר` - ליטר
 * * `קילוגרם` - קילוגרם
 */
export enum SubstanceAmountTypeEnum {
    Valueליטר = 'ליטר',
    Valueקילוגרם = 'קילוגרם',
}

/**
 * * `מזיגה` - מזיגה
 * * `ג'ריקנים` - ג'ריקנים
 */
export enum SubstanceRetrievalMethodEnum {
    Valueמזיגה = 'מזיגה',
    Valueגריקנים = "ג'ריקנים",
}

export interface Tariff {
    /**
     * תעריף פעיל
     * @default false
     */
    active_tariff?: boolean
    id: number
    /**
     * עלות חודשית
     * @format decimal
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    monthly_cost: string
    /**
     * תעריף עבור
     * @maxLength 200
     */
    rate_for: string
}

export interface TariffRequest {
    /**
     * תעריף פעיל
     * @default false
     */
    active_tariff?: boolean
    /**
     * עלות חודשית
     * @format decimal
     * @pattern ^-?\d{0,18}(?:\.\d{0,2})?$
     */
    monthly_cost: string
    /**
     * תעריף עבור
     * @minLength 1
     * @maxLength 200
     */
    rate_for: string
}

export interface TokenObtainPair {
    access: string
    refresh: string
}

export interface TokenObtainPairRequest {
    /** @minLength 1 */
    password: string
    /** @minLength 1 */
    username: string
}

export interface TokenRefresh {
    access: string
}

export interface TokenRefreshRequest {
    /** @minLength 1 */
    refresh: string
}

export interface ToxicReport {
    /** הערות */
    comments?: string | null
    /**
     * מועד יצירה
     * @format date-time
     */
    created_at: string
    id: number
    /** מתחם בריכות */
    organization_branch: number
    organization_branch_name: string
    /** חתימה */
    signature?: string | null
    /**
     * שם מקבל החומר
     * @maxLength 50
     */
    substance_receiver_name: string
    /**
     * תאריך דוח הרעלים
     * @format date-time
     */
    toxic_report_date: string
    toxic_substance_lines: ToxicSubstanceLine[]
    /**
     * מועד עידכון
     * @format date-time
     */
    updated_at: string
}

export interface ToxicReportAttachment {
    /**
     * שם הנספח
     * @maxLength 200
     */
    attachment_name: string
    /**
     * קישור לנספח
     * @format uri
     */
    attachment_url: string
    id: number
    /** דוח פנקס רעלים */
    toxic_report: number
}

export interface ToxicReportAttachmentRequest {
    /**
     * שם הנספח
     * @minLength 1
     * @maxLength 200
     */
    attachment_name: string
    /**
     * קישור לנספח
     * @format binary
     */
    attachment_url: File
    /** דוח פנקס רעלים */
    toxic_report: number
}

export interface ToxicReportRequest {
    /** הערות */
    comments?: string | null
    /** מתחם בריכות */
    organization_branch: number
    /** חתימה */
    signature?: string | null
    /**
     * שם מקבל החומר
     * @minLength 1
     * @maxLength 50
     */
    substance_receiver_name: string
    /**
     * תאריך דוח הרעלים
     * @format date-time
     */
    toxic_report_date: string
    toxic_substance_line_create?: ToxicSubstanceLineCreateRequest[]
}

export interface ToxicSubstance {
    /**
     * קוד חירום
     * @maxLength 20
     */
    emergency_code: string
    /**
     * שם לועזי
     * @maxLength 100
     */
    english_name: string
    /**
     * קבוצת סיכון
     * @maxLength 20
     */
    hazard_group: string
    /**
     * שם עברי
     * @maxLength 100
     */
    hebrew_name: string
    id: number
    /**
     * מספר או"ם
     * @maxLength 20
     */
    un_number: string
}

export interface ToxicSubstanceLine {
    id: number
    substance: ToxicSubstance
    /**
     * כמות החומר
     * @min 0
     * @max 20000
     */
    substance_amount: number
    /** סוג כמות החומר */
    substance_amount_type: SubstanceAmountTypeEnum
    substance_en_name: string
    substance_he_name: string
    /**
     * מספר סידורי של החומר
     * @maxLength 200
     */
    substance_serial_number: string
    /**
     * שם הספק
     * @maxLength 50
     */
    supplier_name: string
    /** דוח פנקס רעלים */
    toxic_report: number
}

export interface ToxicSubstanceLineCreate {
    id: number
    substance: ToxicSubstance
    /**
     * כמות החומר
     * @min 0
     * @max 20000
     */
    substance_amount: number
    /** סוג כמות החומר */
    substance_amount_type: SubstanceAmountTypeEnum
    /** סוג החומר */
    substance_retrieval_method?: SubstanceRetrievalMethodEnum | NullEnum | null
    /**
     * מספר סידורי של החומר
     * @maxLength 200
     */
    substance_serial_number: string
    /**
     * שם הספק
     * @maxLength 50
     */
    supplier_name: string
}

export interface ToxicSubstanceLineCreateRequest {
    /**
     * כמות החומר
     * @min 0
     * @max 20000
     */
    substance_amount: number
    /** סוג כמות החומר */
    substance_amount_type: SubstanceAmountTypeEnum
    /** סוג החומר */
    substance_retrieval_method?: SubstanceRetrievalMethodEnum | NullEnum | null
    /**
     * מספר סידורי של החומר
     * @minLength 1
     * @maxLength 200
     */
    substance_serial_number: string
    /**
     * שם הספק
     * @minLength 1
     * @maxLength 50
     */
    supplier_name: string
}

export interface ToxicSubstanceLineRequest {
    /**
     * כמות החומר
     * @min 0
     * @max 20000
     */
    substance_amount: number
    /** סוג כמות החומר */
    substance_amount_type: SubstanceAmountTypeEnum
    /**
     * מספר סידורי של החומר
     * @minLength 1
     * @maxLength 200
     */
    substance_serial_number: string
    /**
     * שם הספק
     * @minLength 1
     * @maxLength 50
     */
    supplier_name: string
    /** דוח פנקס רעלים */
    toxic_report: number
}

export interface ToxicSubstanceRequest {
    /**
     * קוד חירום
     * @minLength 1
     * @maxLength 20
     */
    emergency_code: string
    /**
     * שם לועזי
     * @minLength 1
     * @maxLength 100
     */
    english_name: string
    /**
     * קבוצת סיכון
     * @minLength 1
     * @maxLength 20
     */
    hazard_group: string
    /**
     * שם עברי
     * @minLength 1
     * @maxLength 100
     */
    hebrew_name: string
    /**
     * מספר או"ם
     * @minLength 1
     * @maxLength 20
     */
    un_number: string
}

export interface TwoFaRequest {
    /** @minLength 1 */
    code: string
    /** @minLength 1 */
    phone: string
}

export interface TwoFaResponse {
    access: string
    refresh: string
}

export interface UserCreate {
    /**
     * כתובת דוא"ל
     * @format email
     * @maxLength 254
     */
    email?: string
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name?: string
    id: number
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * צילום רשיון מפעיל בריכה
     * @format uri
     */
    operator_license_img?: string | null
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format uri
     */
    user_photo?: string | null
    /**
     * שם משתמש
     * שדה חובה. 150 תווים או פחות. אותיות, ספרות ו-@/./+/-/_ בלבד.
     * @maxLength 150
     * @pattern ^[\w.@+-]+$
     */
    username: string
}

export interface UserCreateRequest {
    /**
     * כתובת דוא"ל
     * @format email
     * @maxLength 254
     */
    email?: string
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name?: string
    /**
     * שם משפחה
     * @maxLength 150
     */
    last_name?: string
    /**
     * צילום רשיון מפעיל בריכה
     * @format binary
     */
    operator_license_img?: File | null
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
    /**
     * שם משתמש
     * שדה חובה. 150 תווים או פחות. אותיות, ספרות ו-@/./+/-/_ בלבד.
     * @minLength 1
     * @maxLength 150
     * @pattern ^[\w.@+-]+$
     */
    username: string
}

export interface UserDetails {
    all_pools: string
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name?: string
    full_name: string
    id: number
    manager_in_organization_branches: string
    operator_in_organization_branch: string
    /**
     * צילום רשיון מפעיל בריכה
     * @format uri
     */
    operator_license_img?: string | null
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    role: RoleEnum
    user_manager_in_organization_branch: string
    /**
     * תמונת משתמש
     * @format uri
     */
    user_photo?: string | null
}

export interface UserDetailsRequest {
    /**
     * שם פרטי
     * @maxLength 150
     */
    first_name?: string
    /**
     * צילום רשיון מפעיל בריכה
     * @format binary
     */
    operator_license_img?: File | null
    /**
     * מספר טלפון
     * @maxLength 128
     */
    phone?: string | null
    /**
     * תמונת משתמש
     * @format binary
     */
    user_photo?: File | null
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean
    /** request path */
    path: string
    /** content type of request body */
    type?: ContentType
    /** query params */
    query?: QueryParamsType
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType
    /** request body */
    body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
    secure?: boolean
    format?: ResponseType
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance
    private securityData: SecurityDataType | null = null
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
    private secure?: boolean
    private format?: ResponseType

    constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
        this.secure = secure
        this.format = format
        this.securityWorker = securityWorker
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data
    }

    protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method)

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        }
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem)
        } else {
            return `${formItem}`
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        if (input instanceof FormData) {
            return input
        }
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key]
            const propertyContent: any[] = property instanceof Array ? property : [property]

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
            }

            return formData
        }, new FormData())
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<T> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {}
        const requestParams = this.mergeRequestParams(params, secureParams)
        const responseFormat = format || this.format || undefined

        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body as Record<string, unknown>)
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body)
        }

        return this.instance
            .request({
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type ? { 'Content-Type': type } : {}),
                },
                params: query,
                responseType: responseFormat,
                data: body,
                url: path,
            })
            .then((response) => response.data)
    }
}

/**
 * @title No title
 * @version 0.0.0
 */
export class Api<SecurityDataType extends unknown> {
    http: HttpClient<SecurityDataType>

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http
    }

    apiTokenAuth = {
        /**
         * @description Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         *
         * @tags api-token-auth
         * @name ApiTokenAuthCreate
         * @request POST:/api-token-auth/
         */
        apiTokenAuthCreate: (data: TokenObtainPairRequest, params: RequestParams = {}) =>
            this.http.request<TokenObtainPair, any>({
                path: `/api-token-auth/`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
    apiTokenRefresh = {
        /**
         * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         *
         * @tags api-token-refresh
         * @name ApiTokenRefreshCreate
         * @request POST:/api-token-refresh/
         */
        apiTokenRefreshCreate: (data: TokenRefreshRequest, params: RequestParams = {}) =>
            this.http.request<TokenRefresh, any>({
                path: `/api-token-refresh/`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
    api = {
        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersCreate
         * @request POST:/api/branch/{branchId}/managers/
         * @secure
         */
        apiBranchManagersCreate: (branchId: string, data: ManagerListRequest, params: RequestParams = {}) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/managers/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersDestroy
         * @request DELETE:/api/branch/{branchId}/managers/{id}/
         * @secure
         */
        apiBranchManagersDestroy: (branchId: string, id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/branch/${branchId}/managers/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersList
         * @request GET:/api/branch/{branchId}/managers/
         * @secure
         */
        apiBranchManagersList: ({ branchId, ...query }: ApiBranchManagersListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedManagerListList, any>({
                path: `/api/branch/${branchId}/managers/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersPartialUpdate
         * @request PATCH:/api/branch/{branchId}/managers/{id}/
         * @secure
         */
        apiBranchManagersPartialUpdate: (
            branchId: string,
            id: number,
            data: PatchedManagerListRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/managers/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersRetrieve
         * @request GET:/api/branch/{branchId}/managers/{id}/
         * @secure
         */
        apiBranchManagersRetrieve: (branchId: string, id: number, params: RequestParams = {}) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/managers/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchManagersUpdate
         * @request PUT:/api/branch/{branchId}/managers/{id}/
         * @secure
         */
        apiBranchManagersUpdate: (branchId: string, id: number, data: ManagerListRequest, params: RequestParams = {}) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/managers/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsCreate
         * @request POST:/api/branch/{branchId}/operators/
         * @secure
         */
        apiBranchOperatorsCreate: (branchId: string, data: ManagerListRequest, params: RequestParams = {}) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/operators/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsDestroy
         * @request DELETE:/api/branch/{branchId}/operators/{id}/
         * @secure
         */
        apiBranchOperatorsDestroy: (branchId: string, id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/branch/${branchId}/operators/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsList
         * @request GET:/api/branch/{branchId}/operators/
         * @secure
         */
        apiBranchOperatorsList: ({ branchId, ...query }: ApiBranchOperatorsListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedManagerListList, any>({
                path: `/api/branch/${branchId}/operators/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsPartialUpdate
         * @request PATCH:/api/branch/{branchId}/operators/{id}/
         * @secure
         */
        apiBranchOperatorsPartialUpdate: (
            branchId: string,
            id: number,
            data: PatchedManagerListRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/operators/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsRetrieve
         * @request GET:/api/branch/{branchId}/operators/{id}/
         * @secure
         */
        apiBranchOperatorsRetrieve: (branchId: string, id: number, params: RequestParams = {}) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/operators/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiBranchOperatorsUpdate
         * @request PUT:/api/branch/{branchId}/operators/{id}/
         * @secure
         */
        apiBranchOperatorsUpdate: (
            branchId: string,
            id: number,
            data: ManagerListRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<ManagerList, any>({
                path: `/api/branch/${branchId}/operators/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchCreate
         * @request POST:/api/organization-branch/
         * @secure
         */
        apiOrganizationBranchCreate: (data: OrganizationBranchRequest, params: RequestParams = {}) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchDestroy
         * @request DELETE:/api/organization-branch/{branchId}/
         * @secure
         */
        apiOrganizationBranchDestroy: (branchId: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/organization-branch/${branchId}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchList
         * @request GET:/api/organization-branch/
         * @secure
         */
        apiOrganizationBranchList: (query: ApiOrganizationBranchListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedOrganizationBranchList, any>({
                path: `/api/organization-branch/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchMakeManagerCreate
         * @request POST:/api/organization-branch/{branchId}/make-manager/{userId}/
         * @secure
         */
        apiOrganizationBranchMakeManagerCreate: (branchId: number, userId: string, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/organization-branch/${branchId}/make-manager/${userId}/`,
                method: 'POST',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchMakeOperatorCreate
         * @request POST:/api/organization-branch/{branchId}/make-operator/{userId}/
         * @secure
         */
        apiOrganizationBranchMakeOperatorCreate: (
            branchId: number,
            userId: string,
            data: OrganizationBranchRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/${branchId}/make-operator/${userId}/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchMakeUserManagerCreate
         * @request POST:/api/organization-branch/{branchId}/make-user-manager/{userId}/
         * @secure
         */
        apiOrganizationBranchMakeUserManagerCreate: (branchId: number, userId: string, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/organization-branch/${branchId}/make-user-manager/${userId}/`,
                method: 'POST',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchPartialUpdate
         * @request PATCH:/api/organization-branch/{branchId}/
         * @secure
         */
        apiOrganizationBranchPartialUpdate: (
            branchId: number,
            data: PatchedOrganizationBranchRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/${branchId}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchRetrieve
         * @request GET:/api/organization-branch/{branchId}/
         * @secure
         */
        apiOrganizationBranchRetrieve: (branchId: number, params: RequestParams = {}) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/${branchId}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationBranchUpdate
         * @request PUT:/api/organization-branch/{branchId}/
         * @secure
         */
        apiOrganizationBranchUpdate: (branchId: number, data: OrganizationBranchRequest, params: RequestParams = {}) =>
            this.http.request<OrganizationBranch, any>({
                path: `/api/organization-branch/${branchId}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationCreate
         * @request POST:/api/organization/
         * @secure
         */
        apiOrganizationCreate: (data: OrganizationRequest, params: RequestParams = {}) =>
            this.http.request<Organization, any>({
                path: `/api/organization/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationDestroy
         * @request DELETE:/api/organization/{id}/
         * @secure
         */
        apiOrganizationDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/organization/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationList
         * @request GET:/api/organization/
         * @secure
         */
        apiOrganizationList: (query: ApiOrganizationListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedOrganizationList, any>({
                path: `/api/organization/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationPartialUpdate
         * @request PATCH:/api/organization/{id}/
         * @secure
         */
        apiOrganizationPartialUpdate: (id: number, data: PatchedOrganizationRequest, params: RequestParams = {}) =>
            this.http.request<Organization, any>({
                path: `/api/organization/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationRetrieve
         * @request GET:/api/organization/{id}/
         * @secure
         */
        apiOrganizationRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<Organization, any>({
                path: `/api/organization/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiOrganizationUpdate
         * @request PUT:/api/organization/{id}/
         * @secure
         */
        apiOrganizationUpdate: (id: number, data: OrganizationRequest, params: RequestParams = {}) =>
            this.http.request<Organization, any>({
                path: `/api/organization/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolCreate
         * @request POST:/api/pool/
         * @secure
         */
        apiPoolCreate: (data: PoolRequest, params: RequestParams = {}) =>
            this.http.request<Pool, any>({
                path: `/api/pool/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolDestroy
         * @request DELETE:/api/pool/{id}/
         * @secure
         */
        apiPoolDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/pool/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolList
         * @request GET:/api/pool/
         * @secure
         */
        apiPoolList: (query: ApiPoolListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedPoolList, any>({
                path: `/api/pool/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentCreate
         * @request POST:/api/pool-log-report-attachment/
         * @secure
         */
        apiPoolLogReportAttachmentCreate: (data: PoolLogReportAttachmentRequest, params: RequestParams = {}) =>
            this.http.request<PoolLogReportAttachment, any>({
                path: `/api/pool-log-report-attachment/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentDestroy
         * @request DELETE:/api/pool-log-report-attachment/{id}/
         * @secure
         */
        apiPoolLogReportAttachmentDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/pool-log-report-attachment/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentList
         * @request GET:/api/pool-log-report-attachment/
         * @secure
         */
        apiPoolLogReportAttachmentList: (query: ApiPoolLogReportAttachmentListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedPoolLogReportAttachmentList, any>({
                path: `/api/pool-log-report-attachment/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentPartialUpdate
         * @request PATCH:/api/pool-log-report-attachment/{id}/
         * @secure
         */
        apiPoolLogReportAttachmentPartialUpdate: (
            id: number,
            data: PatchedPoolLogReportAttachmentRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<PoolLogReportAttachment, any>({
                path: `/api/pool-log-report-attachment/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentRetrieve
         * @request GET:/api/pool-log-report-attachment/{id}/
         * @secure
         */
        apiPoolLogReportAttachmentRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<PoolLogReportAttachment, any>({
                path: `/api/pool-log-report-attachment/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolLogReportAttachmentUpdate
         * @request PUT:/api/pool-log-report-attachment/{id}/
         * @secure
         */
        apiPoolLogReportAttachmentUpdate: (
            id: number,
            data: PoolLogReportAttachmentRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<PoolLogReportAttachment, any>({
                path: `/api/pool-log-report-attachment/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportCreate
         * @request POST:/api/pool-log-report/
         * @secure
         */
        apiPoolLogReportCreate: (data: PoolLogReportRequest, params: RequestParams = {}) =>
            this.http.request<PoolLogReport, any>({
                path: `/api/pool-log-report/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportDestroy
         * @request DELETE:/api/pool-log-report/{id}/
         * @secure
         */
        apiPoolLogReportDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/pool-log-report/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportList
         * @request GET:/api/pool-log-report/
         * @secure
         */
        apiPoolLogReportList: (query: ApiPoolLogReportListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedPoolLogReportList, any>({
                path: `/api/pool-log-report/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportPartialUpdate
         * @request PATCH:/api/pool-log-report/{id}/
         * @secure
         */
        apiPoolLogReportPartialUpdate: (id: number, data: PatchedPoolLogReportRequest, params: RequestParams = {}) =>
            this.http.request<PoolLogReport, any>({
                path: `/api/pool-log-report/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportRetrieve
         * @request GET:/api/pool-log-report/{id}/
         * @secure
         */
        apiPoolLogReportRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<PoolLogReport, any>({
                path: `/api/pool-log-report/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiPoolLogReportUpdate
         * @request PUT:/api/pool-log-report/{id}/
         * @secure
         */
        apiPoolLogReportUpdate: (id: number, data: PoolLogReportRequest, params: RequestParams = {}) =>
            this.http.request<PoolLogReport, any>({
                path: `/api/pool-log-report/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolPartialUpdate
         * @request PATCH:/api/pool/{id}/
         * @secure
         */
        apiPoolPartialUpdate: (id: number, data: PatchedPoolRequest, params: RequestParams = {}) =>
            this.http.request<Pool, any>({
                path: `/api/pool/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolRetrieve
         * @request GET:/api/pool/{id}/
         * @secure
         */
        apiPoolRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<Pool, any>({
                path: `/api/pool/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiPoolUpdate
         * @request PUT:/api/pool/{id}/
         * @secure
         */
        apiPoolUpdate: (id: number, data: PoolRequest, params: RequestParams = {}) =>
            this.http.request<Pool, any>({
                path: `/api/pool/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
         *
         * @tags api
         * @name ApiSchemaRetrieve
         * @request GET:/api/schema/
         * @secure
         */
        apiSchemaRetrieve: (query: ApiSchemaRetrieveParams, params: RequestParams = {}) =>
            this.http.request<Record<string, any>, any>({
                path: `/api/schema/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiSubscribeNotifyCreate
         * @request POST:/api/subscribe-notify
         * @secure
         */
        apiSubscribeNotifyCreate: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/subscribe-notify`,
                method: 'POST',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiSubscribeRetrieve
         * @request GET:/api/subscribe
         * @secure
         */
        apiSubscribeRetrieve: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/subscribe`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionCreate
         * @request POST:/api/subscription/
         * @secure
         */
        apiSubscriptionCreate: (data: SubscriptionRequest, params: RequestParams = {}) =>
            this.http.request<Subscription, any>({
                path: `/api/subscription/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionDestroy
         * @request DELETE:/api/subscription/{id}/
         * @secure
         */
        apiSubscriptionDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/subscription/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionList
         * @request GET:/api/subscription/
         * @secure
         */
        apiSubscriptionList: (query: ApiSubscriptionListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedSubscriptionList, any>({
                path: `/api/subscription/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionPartialUpdate
         * @request PATCH:/api/subscription/{id}/
         * @secure
         */
        apiSubscriptionPartialUpdate: (id: number, data: PatchedSubscriptionRequest, params: RequestParams = {}) =>
            this.http.request<Subscription, any>({
                path: `/api/subscription/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionRetrieve
         * @request GET:/api/subscription/{id}/
         * @secure
         */
        apiSubscriptionRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<Subscription, any>({
                path: `/api/subscription/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiSubscriptionUpdate
         * @request PUT:/api/subscription/{id}/
         * @secure
         */
        apiSubscriptionUpdate: (id: number, data: SubscriptionRequest, params: RequestParams = {}) =>
            this.http.request<Subscription, any>({
                path: `/api/subscription/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiTariffCreate
         * @request POST:/api/tariff/
         * @secure
         */
        apiTariffCreate: (data: TariffRequest, params: RequestParams = {}) =>
            this.http.request<Tariff, any>({
                path: `/api/tariff/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiTariffDestroy
         * @request DELETE:/api/tariff/{id}/
         * @secure
         */
        apiTariffDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/tariff/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiTariffList
         * @request GET:/api/tariff/
         * @secure
         */
        apiTariffList: (query: ApiTariffListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedTariffList, any>({
                path: `/api/tariff/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiTariffPartialUpdate
         * @request PATCH:/api/tariff/{id}/
         * @secure
         */
        apiTariffPartialUpdate: (id: number, data: PatchedTariffRequest, params: RequestParams = {}) =>
            this.http.request<Tariff, any>({
                path: `/api/tariff/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiTariffRetrieve
         * @request GET:/api/tariff/{id}/
         * @secure
         */
        apiTariffRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<Tariff, any>({
                path: `/api/tariff/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiTariffUpdate
         * @request PUT:/api/tariff/{id}/
         * @secure
         */
        apiTariffUpdate: (id: number, data: TariffRequest, params: RequestParams = {}) =>
            this.http.request<Tariff, any>({
                path: `/api/tariff/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiToxicReportAttachmentCreate
         * @request POST:/api/toxic-report-attachment/
         * @secure
         */
        apiToxicReportAttachmentCreate: (data: ToxicReportAttachmentRequest, params: RequestParams = {}) =>
            this.http.request<ToxicReportAttachment, any>({
                path: `/api/toxic-report-attachment/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiToxicReportAttachmentDestroy
         * @request DELETE:/api/toxic-report-attachment/{id}/
         * @secure
         */
        apiToxicReportAttachmentDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/toxic-report-attachment/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiToxicReportAttachmentList
         * @request GET:/api/toxic-report-attachment/
         * @secure
         */
        apiToxicReportAttachmentList: (query: ApiToxicReportAttachmentListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedToxicReportAttachmentList, any>({
                path: `/api/toxic-report-attachment/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiToxicReportAttachmentPartialUpdate
         * @request PATCH:/api/toxic-report-attachment/{id}/
         * @secure
         */
        apiToxicReportAttachmentPartialUpdate: (
            id: number,
            data: PatchedToxicReportAttachmentRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<ToxicReportAttachment, any>({
                path: `/api/toxic-report-attachment/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiToxicReportAttachmentRetrieve
         * @request GET:/api/toxic-report-attachment/{id}/
         * @secure
         */
        apiToxicReportAttachmentRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<ToxicReportAttachment, any>({
                path: `/api/toxic-report-attachment/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiToxicReportAttachmentUpdate
         * @request PUT:/api/toxic-report-attachment/{id}/
         * @secure
         */
        apiToxicReportAttachmentUpdate: (id: number, data: ToxicReportAttachmentRequest, params: RequestParams = {}) =>
            this.http.request<ToxicReportAttachment, any>({
                path: `/api/toxic-report-attachment/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportCreate
         * @request POST:/api/toxic-report/
         * @secure
         */
        apiToxicReportCreate: (data: ToxicReportRequest, params: RequestParams = {}) =>
            this.http.request<ToxicReport, any>({
                path: `/api/toxic-report/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportDestroy
         * @request DELETE:/api/toxic-report/{id}/
         * @secure
         */
        apiToxicReportDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/toxic-report/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportList
         * @request GET:/api/toxic-report/
         * @secure
         */
        apiToxicReportList: (query: ApiToxicReportListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedToxicReportList, any>({
                path: `/api/toxic-report/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportPartialUpdate
         * @request PATCH:/api/toxic-report/{id}/
         * @secure
         */
        apiToxicReportPartialUpdate: (id: number, data: PatchedToxicReportRequest, params: RequestParams = {}) =>
            this.http.request<ToxicReport, any>({
                path: `/api/toxic-report/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportRetrieve
         * @request GET:/api/toxic-report/{id}/
         * @secure
         */
        apiToxicReportRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<ToxicReport, any>({
                path: `/api/toxic-report/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicReportUpdate
         * @request PUT:/api/toxic-report/{id}/
         * @secure
         */
        apiToxicReportUpdate: (id: number, data: ToxicReportRequest, params: RequestParams = {}) =>
            this.http.request<ToxicReport, any>({
                path: `/api/toxic-report/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicSubstanceLineCreate
         * @request POST:/api/toxic-substance-line/
         * @secure
         */
        apiToxicSubstanceLineCreate: (data: ToxicSubstanceLineRequest, params: RequestParams = {}) =>
            this.http.request<ToxicSubstanceLine, any>({
                path: `/api/toxic-substance-line/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicSubstanceLineDestroy
         * @request DELETE:/api/toxic-substance-line/{id}/
         * @secure
         */
        apiToxicSubstanceLineDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/toxic-substance-line/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicSubstanceLineList
         * @request GET:/api/toxic-substance-line/
         * @secure
         */
        apiToxicSubstanceLineList: (query: ApiToxicSubstanceLineListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedToxicSubstanceLineList, any>({
                path: `/api/toxic-substance-line/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicSubstanceLinePartialUpdate
         * @request PATCH:/api/toxic-substance-line/{id}/
         * @secure
         */
        apiToxicSubstanceLinePartialUpdate: (
            id: number,
            data: PatchedToxicSubstanceLineRequest,
            params: RequestParams = {}
        ) =>
            this.http.request<ToxicSubstanceLine, any>({
                path: `/api/toxic-substance-line/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicSubstanceLineRetrieve
         * @request GET:/api/toxic-substance-line/{id}/
         * @secure
         */
        apiToxicSubstanceLineRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<ToxicSubstanceLine, any>({
                path: `/api/toxic-substance-line/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``, deriving the permission type from the particular action to be performed.. As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
         *
         * @tags api
         * @name ApiToxicSubstanceLineUpdate
         * @request PUT:/api/toxic-substance-line/{id}/
         * @secure
         */
        apiToxicSubstanceLineUpdate: (id: number, data: ToxicSubstanceLineRequest, params: RequestParams = {}) =>
            this.http.request<ToxicSubstanceLine, any>({
                path: `/api/toxic-substance-line/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiToxicSubstanceList
         * @request GET:/api/toxic-substance/
         * @secure
         */
        apiToxicSubstanceList: (query: ApiToxicSubstanceListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedToxicSubstanceList, any>({
                path: `/api/toxic-substance/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiToxicSubstanceRetrieve
         * @request GET:/api/toxic-substance/{id}/
         * @secure
         */
        apiToxicSubstanceRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<ToxicSubstance, any>({
                path: `/api/toxic-substance/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersCreate
         * @request POST:/api/users/
         * @secure
         */
        apiUsersCreate: (data: UserCreateRequest, params: RequestParams = {}) =>
            this.http.request<UserCreate, any>({
                path: `/api/users/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersDestroy
         * @request DELETE:/api/users/{id}/
         * @secure
         */
        apiUsersDestroy: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/users/${id}/`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersList
         * @request GET:/api/users/
         * @secure
         */
        apiUsersList: (query: ApiUsersListParams, params: RequestParams = {}) =>
            this.http.request<PaginatedUserDetailsList, any>({
                path: `/api/users/`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersMeRetrieve
         * @request GET:/api/users/me/
         * @secure
         */
        apiUsersMeRetrieve: (params: RequestParams = {}) =>
            this.http.request<UserDetails, any>({
                path: `/api/users/me/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersPartialUpdate
         * @request PATCH:/api/users/{id}/
         * @secure
         */
        apiUsersPartialUpdate: (id: number, data: PatchedUserDetailsRequest, params: RequestParams = {}) =>
            this.http.request<UserDetails, any>({
                path: `/api/users/${id}/`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersRetrieve
         * @request GET:/api/users/{id}/
         * @secure
         */
        apiUsersRetrieve: (id: number, params: RequestParams = {}) =>
            this.http.request<UserDetails, any>({
                path: `/api/users/${id}/`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags api
         * @name ApiUsersUpdate
         * @request PUT:/api/users/{id}/
         * @secure
         */
        apiUsersUpdate: (id: number, data: UserDetailsRequest, params: RequestParams = {}) =>
            this.http.request<UserDetails, any>({
                path: `/api/users/${id}/`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
    login = {
        /**
         * @description login which starts a 2fa flow
         *
         * @tags login
         * @name LoginCreate
         * @request POST:/login
         * @secure
         */
        loginCreate: (data: LoginRequest, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/login`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),
    }
    twoFa = {
        /**
         * @description Checking the verfication code and the date
         *
         * @tags two-fa
         * @name TwoFaCreate
         * @request POST:/two-fa
         * @secure
         */
        twoFaCreate: (data: TwoFaRequest, params: RequestParams = {}) =>
            this.http.request<TwoFaResponse, any>({
                path: `/two-fa`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
}
