import { Api, HttpClient } from '~/generated/api'
import globalState from './state'
import { QueryClient } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

export function serializeParams(params: Record<string, string>) {
    const queryString = []

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const value = params[key]

            if (Array.isArray(value)) {
                value.forEach((item) => {
                    queryString.push(
                        `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
                    )
                })
            } else {
                queryString.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                )
            }
        }
    }

    return `${queryString.join('&')}`
}

export function apiClientFactory() {
    const { accessToken } = globalState.getState()

    const headers: HeadersInit = {}

    if (accessToken) {
        headers['Authorization'] = 'Bearer ' + accessToken
    }

    // TODO: add support for multiple languages
    headers['Accept-Language'] = 'he'

    const httpClient = new HttpClient({
        headers,
        baseURL: import.meta.env.VITE_API_URL,
        paramsSerializer: {
            serialize: (params) => {
                const searchParams = serializeParams(params)
                return searchParams
            },
        },
    })

    return new Api(httpClient)
}

export const CacheKeys = {
    getOperators: 'GET_OPERATORS',
    getAllPools: 'GET_POOLS',
    getUserDetails: 'GET_USER_DETAILS',
    getManagers: 'GET_MANAGERS',
    getToxicSubstanceList: 'TOXIC_LIST',
} as const

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 60 * 60 * 24, // 1 day persistance of queries
            staleTime: 15000,
        },
    },
})

if (typeof document != 'undefined') {
    const localStoragePersister = createSyncStoragePersister({
        storage: window.localStorage,
    })

    persistQueryClient({
        queryClient,
        persister: localStoragePersister,
    })
}

// export async function handleExceptions(error: unknown) {
//   if (error instanceof AxiosError) {
//     const status = error.response?.status
//     const data = error.response?.data as HadrachaError
//     console.log({ status, data })
//     switch (status) {
//       case 401:
//         Sentry.addBreadcrumb({
//           category: 'auth',
//           message: `User with an id of ${authStore.getState().userId} failed an authentication check while accessing the API`,
//           level: 'error',
//         })

//         logout()
//       case 400:
//         if (data.type === 'validation_error') {
//           return {
//             errors: formatIssues({ issues: data.errors }),
//             success: false,
//           }
//         }

//         Sentry.captureException(error)

//         const { addToast } = messagesStore.getState()

//         addToast({
//           type: 'error',
//           description: String(data),
//           title: 'Something went wrong',
//         })

//         return { errors: null, success: false }
//       default:
//         Sentry.captureException(error)
//         console.log(error)
//         return { errors: null, success: false }
//     }
//   }

//   Sentry.captureException(error)

//   return { errors: null, success: false }
// }

export const api = apiClientFactory()
